<template>
  <div class="container" id="note">
    <h2>解决您的备品、备件需求，就是我们的使命。</h2>
    <p>
      若您希望进一步了解巨立备品、备件，或者您正在使用的巨立产品有任何问题，请留下您的宝贵信息，以使我们为您提供专业的服务。
    </p>
    <el-form
      ref="ruleForm"
      label-position="left"
      :model="form"
      :rules="rules"
      class="demo-form-inline"
    >
      <el-form-item label="留言人：" prop="tuesday">
        <el-input v-model="form.tuesday"></el-input>
      </el-form-item>
      <el-form-item label="手机号：" prop="mobile">
        <el-input v-model="form.mobile"></el-input>
      </el-form-item>
      <el-form-item label="邮箱：" prop="postBox">
        <el-input v-model="form.postBox"></el-input>
      </el-form-item>
      <el-form-item label="公司名称：" prop="companyName">
        <el-input v-model="form.companyName"></el-input>
      </el-form-item>
      <el-form-item label="所在区域：" prop="region">
        <el-input v-model="form.region"></el-input>
      </el-form-item>
      <el-form-item class="full-width" label="留言内容：" prop="content">
        <el-input type="textarea" rows="6" v-model="form.content"></el-input>
      </el-form-item>
      <el-form-item class="full-width">
        <el-button type="primary" @click="onSubmit">提 交</el-button>
      </el-form-item>
      <el-form-item class="full-width">
        <div class="tips">
          特别说明：1、*为必填项；2、我们尊重并保护您的个人信息的安全
        </div>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import { reactive, toRefs, ref, getCurrentInstance } from "vue";
import { useRouter } from "vue-router";
export default {
  name: "Note",
  setup(props, { emit }) {
    const router = useRouter();
    const ruleForm = ref(null);
    const { proxy } = getCurrentInstance();

    emit("public_header", false);

    const state = reactive({
      form: {
        tuesday: "",
        mobile: "",
        postBox: "",
        companyName: "",
        region: "",
        content: "",
      },
      rules: {
        tuesday: [
          {
            required: true,
            message: "请输入留言人",
            trigger: "blur",
          },
        ],
        mobile: [
          {
            required: true,
            message: "请输入手机号",
            trigger: "blur",
          },
        ],
        postBox: [
          {
            required: true,
            message: "请输入邮箱",
            trigger: "blur",
          },
          {
            type: "email",
            message: "请输入正确的邮箱地址",
            trigger: "blur",
          },
        ],
      },
    });

    const onSubmit = () => {
      ruleForm.value.validate((valid) => {
        if (valid) {
          proxy.$api.common.setNote(state.form).then(() => {
            proxy.$defined.tip("提交成功！", "success");
            setTimeout(() => {
              router.push({
                path: "/",
              });
            }, 1000);
          });
        } else {
          return false;
        }
      });
    };

    return {
      ...toRefs(state),
      onSubmit,
      ruleForm,
    };
  },
};
</script>
<style lang="scss">
#note {
  h2 {
    font-size: 32px;
  }

  P {
    font-size: 20px;
    color: #5c5c5c;
    margin: 30px 0;
  }

  .el-form {
    .el-form-item {
      display: inline-block;
      width: 33%;
      margin-bottom: 30px;

      label {
        font-size: 18px;
        padding-left: 30px;
        width: 150px !important;
      }

      .el-form-item__content {
        margin-left: 150px !important;
      }

      input {
        height: 50px;
        line-height: 50px;
      }

      input,
      textarea {
        font-size: 18px;
      }

      button {
        width: 160px;
        height: 50px;
        line-height: 50px;
        font-size: 18px;
        border-radius: 4px;
      }
    }

    .el-form-item.full-width {
      width: 100%;
    }

    .tips {
      color: red;
    }
  }
}

@media screen and (max-width: 700px) {
  #note {
    margin: 0;
    padding: 10px;

    .el-form {
      .el-form-item {
        display: inline-block;
        width: 100%;

        label {
          padding-left: 0;
          width: 110px !important;
        }

        .el-form-item__content {
          margin-left: 110px !important;
        }
      }
    }
  }
}
</style>
